<template>
  <div id="app">
    <router-view/>
  </div>
</template>


<script>
import WebFontLoader from "webfontloader";
export default {
  components: {
    WebFontLoader,
  },
  methods: {
    loadFonts() {
      WebFont.load({
        google: {
          families: ["Montserrat:300,400,500,600,700"],
        },
        classes: false,
        active: () => {
          this.fontsLoaded = true;
        },
      });
    },
  },
  mounted() {
    this.loadFonts();
  },
};
</script>
<style>
#app {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

</style>
