<template>
  <div>
    <div class="pb-10">
      <div class="container">
        <h1 class="text-center font-medium text-blue-3 text-lg">
          Our Testimonials
        </h1>
        <p
          class="py-3 text-center leading-tight text-2xl font-semibold text-gray-900"
        >
        What Our Clients Are Saying
        </p>
        <div class="bg-blue-3 mt-2 w-12 h-0.5 mx-auto"></div>

        <div class="mt-6">
          <carousel
            :autoplay="true"
            :loop="true"
            :autoplaySpeed="2500"
            :autoplayTimeout="6000"
            :responsive="{
              0: { items: 1, nav: false, dots: true },
              767: { items: 2, nav: false, dots: true },
              1000: { items: 3, nav: false, dots: true },
            }"
          >
            <div v-for="(item, i) in testimonials" :key="i" class="px-1">
              <div class="p-5 rounded-md bg-blue-3">
                <div class="pt-3 pb-20">
                  <p class="h-40 text-sm text-center text-white">
                    {{ item.p }}
                  </p>
                </div>
                <div class="pt-4 text-center">
                  <p class="text-base font-semibold text-white">
                    {{ item.client }}
                  </p>
                  <div class="text-yellow-400">
                    <i class="fa-sharp fa-solid fa-star"></i>
                    <i class="fa-sharp fa-solid fa-star"></i>
                    <i class="fa-sharp fa-solid fa-star"></i>
                    <i class="fa-sharp fa-solid fa-star"></i>
                    <i class="fa-sharp fa-solid fa-star"></i>
                  </div>
                </div>
                <div class="absolute w-20 bottom-2 right-4">
                  <img src="/images/google-review.png" alt="" class="" />
                </div>
              </div>
            </div>
          </carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";

export default {
  components: {
    carousel,
  },
  data() {
    return {
      testimonials: [
        {
          p: "Real Solution provided top notch service and their support after the service is great. Always answer all my queries. The price is very reasonable. I would like to recommend them for waterproofing service. They are real experts and their job is clean and they understood the issue properly before take actions",
          client: "Sathish Kumar",
        },
        {
          p: "Real Solution is one of the best waterproofing specialist in KL. Jason is very helpful in providing reliable solution to the water leakage problem in my house. The price is also very reasonable. Highly recommended for the waterproofing service.",
          client: "Wei Liang Soh",
        },
        {
          p: "Very professional and explain the problem in details. Happy with the workmanship by the staff. So far so good.",
          client: "Yusri MY",
        },
        {
          p: "They are showing their professional on this filed. I am blessed to have the responsible and reputable company",
          client: "Izabella Ho",
        },
        {
          p: "Very fast and reliable",
          client: "MEGALAH 18",
        },
      ],
    };
  },
};
</script>
