<template>
  <div>
    <WaBtn />
    <MainBanner />

    <div
      class="relative w-5/6 mx-auto bg-center bg-no-repeat bg-cover rounded-lg -top-10 md:-top-14"
      style="background-image: url('/images/bg-orange.jpg')"
    >
      <div class="py-10 lg:px-10">
        <div class="text-center">
          <h1 class="text-2xl font-bold leading-tight text-white md:text-3xl">
            Non-Obligatory Consultation Session With Our Team Of Professionals
          </h1>
          <div class="mt-4">
            <p
              class="py-2 mx-auto text-gray-800 duration-200 ease-linear transform bg-white rounded-md hover:scale-110 w-44"
            >
              <a href="https://wa.me/60182034318">Whatsapp Us</a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- product section -->
    <div class="pb-10">
      <div class="container">
        <h1 class="text-lg font-medium text-center text-blue-3">
          OUR SERVICES
        </h1>
        <p
          class="py-3 text-2xl font-semibold leading-tight text-center text-gray-900"
        >
          Expertise in Waterproofing
        </p>
        <div class="bg-blue-3 mt-2 w-12 h-0.5 mx-auto"></div>

        <!-- content -->
        <div class="mt-5">
          <h1
            class="pb-2 text-xl font-semibold text-center uppercase text-blue-2"
          >
            Roof
          </h1>
          <div class="md:flex md:flex-wrap lg:justify-center">
          <div v-for="(item, i) in services" :key="i" class="mb-4 md:px-1 md:w-1/2 lg:w-1/3">
            <div class="text-center border rounded-md">
              <img
                :src="'/images/' + item.image + '.jpg'"
                :alt="item.alt"
                class="rounded-t-md"
              />
              <div class="p-4">
                <h4 class="font-semibold text-blue-2">{{ item.alt }}</h4>
                <!-- <p class="pt-3 text-sm text-gray-600 md:h-24 lg:h-32">
                  {{ item.p }}
                </p> -->
              </div>
            </div>
          </div>
        </div>
        </div>
        <div class="mt-5">
          <h1
            class="pb-2 text-xl font-semibold text-center uppercase text-blue-2"
          >
            Waterproofing
          </h1>
          <div class="pt-5 md:flex md:flex-wrap lg:justify-center">
          <div v-for="(item, i) in waterproofing" :key="i" class="mb-4 md:w-1/2 lg:w-1/4 md:px-1">
            <div class="text-center border rounded-md">
              <img
                :src="'/images/' + item.image + '.jpg'"
                :alt="item.alt"
                class="rounded-t-md"
              />
              <div class="p-4">
                <h4 class="font-semibold leading-tight md:h-10 xl:h-auto text-blue-2">{{ item.alt }}</h4>
                <!-- <p class="pt-3 text-sm text-gray-600 md:h-24 lg:h-32">
                  {{ item.p }}
                </p> -->
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>

    <!-- sub banner -->
    <section
      class="py-20 bg-center bg-no-repeat bg-cover md:py-28"
      style="background-image: url('/images/bg-sub.jpg')"
    >
      <div class="container">
        <div class="text-white md:w-2/3">
          <p
            class="py-1 mb-3 text-lg font-medium text-center text-white w-60 bg-blue-1"
          >
            Real Solutions
          </p>
          <h1 class="text-4xl font-bold md:text-5xl">
            Trusted <br class="hidden lg:block"> <span class="text-blue-1">Waterproofing</span> Company
          </h1>
          <p class="pt-3">
            From jobs around the home to construction site for waterproofing the
            basements, tanks, bathrooms, terraces, roofs and swimming pools –
            we’ve got you covered.
          </p>
        </div>
      </div>
    </section>

    <!-- why choose us -->
    <div class="py-10">
      <div class="container">
        <h1 class="text-lg font-medium text-center text-blue-3">
          Why Choose Us
        </h1>
        <p
          class="py-3 text-2xl font-semibold leading-tight text-center text-gray-900"
        >
          Roofing Specialist In Action
        </p>
        <div class="bg-blue-3 mt-2 w-12 h-0.5 mx-auto"></div>

        <div class="mt-8 md:flex md:flex-wrap">
          <div v-for="(item, i) in choose" :key="i" class="w-2/3 mx-auto mb-4 md:w-1/2 lg:w-1/4 md:px-2">
            <div class="p-5 text-center rounded-md bg-blue-2">
              <img
                :src="'/images/' + item.image + '.png'"
                :alt="item.alt"
                class="w-20 mx-auto"
              />
              <div class="pt-4">
                <h4 class="text-base font-semibold leading-tight text-white lg:h-8">
                  {{ item.alt }}
                </h4>
                <p class="pt-4 text-sm leading-tight text-gray-100 lg:h-20">
                  {{ item.p }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Testimonials />

    <!-- about us -->
    <div class="py-10 bg-gray-100 lg:py-20">
      <div class="container">
        <div class="bg-white shadow-lg lg:w-5/6 xl:w-4/6 lg:mx-auto lg:relative">
          <!-- offset layout -->
          <div class="p-4 font-semibold bg-white border-8 lg:shadow-md lg:absolute lg:-left-10 lg:-top-10 xl:-left-4 xl:w-96 lg:w-1/2 border-blue-1">
            <img src="/images/about-us.jpg" alt="" class="mx-auto">
          </div>

          <!-- right layout -->
          <div class="p-5 lg:py-10 lg:ml-auto lg:w-1/2">
            <h1 class="text-2xl font-semibold text-blue-1">About Us</h1>
            <div class="pt-4 space-y-3 text-sm text-gray-600">
              <p>
                
              </p>
              <p>
                Our waterproofing expertise lies with solving all kinds of water
                leakage issues happening in any kind of place. Some leakage
                examples include leakage in toilets & bathrooms, leaking
                balcony, roof leakage, R.C. flat roof leakage, ceiling water
                seepage, water intrusion into basement, water seepage through
                walls etc. With over 20 years of experience in this field, our
                skills and expertise have been tested, refined and proven.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- footer -->
    <section
      id="enquiry"
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-enquiry.jpg')"
    >
      <div class="container lg:flex lg:items-center">
        <div class="md:w-2/3 md:mx-auto lg:w-1/2 lg:order-2">
          <!-- enquiry form  -->
          <div class="p-6 rounded-md bg-blue-1 bg-opacity-80">
            <div class="text-center">
              <p class="text-3xl font-bold text-white">Send an Enquiry</p>
              <div class="bg-gray-1 w-14 h-0.5 mx-auto my-3"></div>
              <p class="pt-3 text-sm text-gray-100">
                Fill out the form below and we'll get back to you as soon as
                possible
              </p>
            </div>
            <EnquiryForm class="pt-4" />
          </div>
        </div>
        <div class="pt-5 lg:w-1/2 lg:pr-4">
          <img src="/images/main-logo-white.png" alt="" class="w-40 mx-auto lg:w-52">
          <div class="pt-3 text-center text-white">
            <div class="pb-5">
              <h1 class="pb-2 text-xl font-bold">Real Solutions Waterproofing Sdn Bhd</h1>
              <p class="text-base font-normal leading-tight md:text-xl">
                <a href="https://www.waze.com/en/live-map/directions/my/wilayah-persekutuan-kuala-lumpur/kuala-lumpur/84,-jalan-belabas?place=El44NCwgSmFsYW4gQmVsYWJhcywgS2Vwb25nIEJhcnUsIDUyMTAwIEt1YWxhIEx1bXB1ciwgV2lsYXlhaCBQZXJzZWt1dHVhbiBLdWFsYSBMdW1wdXIsIE1hbGF5c2lhIjASLgoUChIJ64kwKSNGzDERATk4Vpukh64QVCoUChIJFxuLuDxGzDERnX6hLmOA-04">
                <i class="text-xl font-semibold fa-brands fa-waze"></i> 84, Jalan Belabas Kepong Baru, Kepong, 52100 Kuala Lumpur
              </a>
              </p>

              <div class="pt-5">
                <p class="font-bold">Message Us on WhatsApp</p>
                <p
                  class="font-medium transition duration-300 transform hover:scale-110 hover:text-yellow-1"
                >
                  <a href="https://wa.me/60182034318" class="">
                    018-203 4318</a
                  >
                </p>
              </div>

              <div class="pt-4 font-medium">
                <p class="font-bold">Call Us</p>
                <p
                  class="font-medium transition duration-300 transform hover:scale-110 hover:text-yellow-1"
                >
                  Mobile : <a href="tel:+60182034318">018-203 4318</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="py-3 bg-blue-2">
      <div class="container mx-auto">
        <h1 class="text-xs text-center text-white lg:text-sm">
          Website maintained by Activa Media. All Rights Reserved.
        </h1>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import MainBanner from "@/components/MainBanner.vue";
import Testimonials from "@/components/Testimonials";
import EnquiryForm from "@/components/EnquiryForm.vue"
import WaBtn from "@/components/WaBtn.vue";

export default {
  name: "Home",
  components: {
    MainBanner,
    Testimonials,
    EnquiryForm,
    WaBtn
  },

  data() {
    return {
      services: [
        {
          image: "service-5",
          alt: "Roof Leak Repair",
          p: "We specialize in roof leak repair and experienced in dealing all aspects of roof leak repair and inspections",
        },
        {
          image: "service-2",
          alt: "Roof Replacement",
          p: "We provide roof replacement service with an affordable price. We ensure the roof replacement takes into consideration of the roof’s overall integrity.",
        },
        {
          image: "service-3",
          alt: "Roof Maintenance",
          p: "We also take into consideration on roof maintenance to avoid roof leaking. Free onsite inspection included.",
        },
        {
          image: "service-1",
          alt: "Roof Installation",
          p: "We guarantee our customers with the best available roof warranty coverage for maximum protection.",
        },
        {
          image: "service-4",
          alt: "Roof Concrete Slab",
          p: "A common issue that surfaces with years of wear and tear, we conduct thermal detection and not visual detection of leakage spots.",
        },
      ],
      waterproofing: [
        {
          image: "service-6",
          alt: "Toilet/Bathroom Waterproofing",
          p: "Facing a leaking bathroom ceiling or unknown water patch? We inspect and employ a no hacking water leakage repair solution.",
        },
        {
          image: "service-8",
          alt: "Balcony Waterproofing",
          p: "Facing yellowish stains, flay ceiling board or visible water dripping? We provide 100% hacking-free waterproofing solution.",
        },
        // {
        //   image: "service-8",
        //   alt: "Toilet/Bathroom Waterproofing & Balcony Waterproofing",
        //   p: "A tricky spot where our waterproofing specialist is able to detect spots of structural leakage and carry out isolation repair.",
        // },
        {
          image: "service-9",
          alt: "Wet Area Waterproofing",
          p: "Ceiling, floors, wall or all kinds of wet area leakages. We are able to conduct a holistic report, isolate and repair the affected area.",
        },
        {
          image: "service-10",
          alt: "PU Injection",
          p: "Our best waterproofing solution used to stop water leakage and seepages by filling the cracks, voids in concrete slabs, joints, floor or even ceiling.",
        },
      ],
      choose: [
        {
          image: "choose-1",
          alt: "Best Material",
          p: "As a waterproofing contractor, we only use quality waterproofing material",
        },
        {
          image: "choose-2",
          alt: "Over 20 Years of Experience",
          p: "We have rectified unsolved water leakages issues with our years of knowledge.",
        },
        {
          image: "choose-3",
          alt: "100% Track Record",
          p: "Fully accountable for every waterproofing project we have committed.",
        },
        {
          image: "choose-4",
          alt: "Customer Care",
          p: "Dedicated customer care to ensure your concerns are well addressed.",
        },
      ],
    };
  },
};
</script>
