<template>
  <div>
    <div
      class="bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-banner.jpg')"
    >
      <header
        v-if="!hideEnquiry"
        class="fixed top-0 left-0 w-full py-4 transition duration-200 ease-linear lg:py-5"
        :class="
          fixtop ? ' bg-white shadow-md z-10' : ' bg-gradient-to-b from-white'
        "
      >
        <div class="container">
          <div class="flex items-center justify-between text-blue-2">
            <img src="/images/main-logo.png" alt="" class="w-28" />
            <div>
              <!-- <div class="duration-200 ease-linear transform hover:scale-110">
                <a href="tel:+60182034788">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-10 h-10 text-blue-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                  />
                </svg>
              </a>
              </div> -->
              <a href="tel:+60182034318">
                <i class="text-5xl fa-solid lg:hidden fa-square-phone"></i>
                <p
                  class="hidden text-xl font-bold leading-tight text-right lg:block"
                >
                  Call Us At: <br />
                  018-203 4318
                </p>
              </a>
            </div>
          </div>
        </div>
      </header>

      <!-- banner tagline -->
      <div class="py-40 md:py-48 lg:py-56">
        <div class="container">
          <div class="md:w-2/3">
            <div class="px-5 py-8 bg-black rounded-md bg-opacity-70 lg:py-10">
              <h1 class="text-3xl font-bold text-white md:text-4xl lg:text-5xl">
                We Are The Most Reliable Waterproofing Contractor

              </h1>
              <p
                class="mt-4 text-sm leading-tight text-white lg:mt-6 lg:text-lg md:text-base"
              >
              We provide professional and cost-effective roofing services in Kuala Lumpur (KL), Selangor and Klang Valley.
              </p>

              <!-- <div v-if="!hideEnquiry" class="mt-6">
                <p
                  class="py-1 font-medium text-center text-white rounded-md bg-blue-1 w-44"
                >
                  <a href="#" v-scroll-to="{ el: '#enquiry', duration: 1500 }">
                    Get A Free Quote
                  </a>
                </p>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fixtop: false,
    };
  },
  methods: {
    scrollListener: function (e) {
      this.fixtop = window.scrollY > 150;
    },
  },
  mounted: function () {
    window.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy: function () {
    window.removeEventListener("scroll", this.scrollListener);
  },
};
</script>
